import './App.css';
import logo from './logo.png';

function App() {
  return (
    <div className="App">
      <header className="Header">
        <img src={logo} alt="logo" className="logo" />
        <p>abuse@email.moe | to report any abuse</p>
      </header>
    </div>
  );
}

export default App;
